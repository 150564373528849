// import { isDEVMODE } from 'wp-content/themes/prism/src/js/_globals'

export default class ArchiveFilters {
  constructor (dom) {
    this.DOM = dom || {}
  }

  init () {
    this.bindEvents()
    console.log('dom', this.DOM)
  }

  bindEvents () {
    this.DOM.yearCheckboxes && this.DOM.yearCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', this.onCheckboxOrCategoryChange.bind(this))
    })
    this.DOM.searchInput && this.DOM.searchInput.addEventListener('input', this.onSearchChange.bind(this))
    this.DOM.categorySelect && this.DOM.categorySelect.addEventListener('change', this.onCheckboxOrCategoryChange.bind(this))
  }

  onCheckboxOrCategoryChange () {
    this.DOM.form.submit()
  }

  onSearchChange () {
    // Don't submit form on every keypress, only when user stops typing
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => {
      this.DOM.form.submit()
    }, 1000)
  }

  destroy() {
    this.DOM.yearCheckboxes && this.DOM.yearCheckboxes.forEach(checkbox => {
      checkbox.removeEventListener('change', this.onCheckboxOrCategoryChange.bind(this))
    })
    this.DOM.searchInput && this.DOM.searchInput.removeEventListener('input', this.onSearchChange.bind(this))
    this.DOM.categorySelect && this.DOM.categorySelect.removeEventListener('change', this.onCheckboxOrCategoryChange.bind(this))
  }


}
