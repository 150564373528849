
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import ArchiveFilters from '../modules/archive-filters'

export default class Archive extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {}

    this.filters = new ArchiveFilters({
      yearCheckboxes: document.querySelectorAll('.Filters input[type="radio"]'),
      searchInput: document.querySelector('.Filters .searchInput'),
      categorySelect: document.querySelector('.Filters select[name="category"]'),
      form: document.querySelector('.Filters form')
    })
    this.filters.init()

    // Modules
  }

  onLeaveCompleted() {
    // Destroy all modules
    this.filters.destroy()
  }
}
