
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'

export default class PageContact extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      contactHubspot: viewStorage.current.querySelector('#contact-hubspot')
    }

    // this.formID_en = 'ef662907-01c7-4f03-8ca7-1899ec0c08a1'
    this.formID = '166364af-f993-489d-897f-89dde34c7401'
    this.portalID = '48158207'
    this.region = 'eu1'

    this.initHubspot()
  }

  initHubspot() {
    if (!window.hbspt) {
      const script = document.createElement('script')
      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
      this.DOM.contactHubspot.appendChild(script)
      script.addEventListener('load', () => this.createHubspotForm())
    } else {
      this.createHubspotForm()
    }
  }

  createHubspotForm() {
    window.hbspt.forms.create({
      formId: this.formID,
      portalId: this.portalID,
      region: this.region,
      target: '#contact-hubspot'
    })
  }

  onLeaveCompleted() {

  }
}
