import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Solutions {
  constructor(container) {
    if (!container) return

    // DOM
    this.DOM = { container }
    this.DOM.cards = [...this.DOM.container.querySelectorAll('.ResearchAxisCard')]

    this.DOM.cards.map(card => {
      card.tween = gsap.fromTo(card, {
        scale: 0.5
      }, {
        scale: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: card,
          start: 'top bottom',
          end: 'top center',
          scrub: 0.5
        }
      })
    })
  }

  destroy() {
    this.DOM.cards.map(card => {
      if (card.tween) card.tween.kill()
    })
  }
}
