import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class Header {
  constructor() {
    if (!domStorage.header) return

    // DOM
    this.DOM = { 
      el: domStorage.header,
      nav: domStorage.header.querySelector('.Nav'),
      navToggle: domStorage.header.querySelector('.NavToggle'),
    }

    // Events
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)

    this.DOM.navToggle.addEventListener('click', () => globalStorage.menuOpen ? this.close() : this.open())
    domStorage.overlay.addEventListener('click', this.close)

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // Observer
    this.setObservers()

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.windowWidthResize = window.innerWidth
  }

  /**
   * Events
   */

  open(e) {
    e && e.preventDefault()

    // Update variables
    globalStorage.menuOpen = true

    // Update body className
    domStorage.body.classList.add('--show-menu')

    // Stop the smooth scroll
    if (viewStorage.hasSmoothScroll) viewStorage.viewScroll.scroll.stop()

    // Add attribute
    this.DOM.nav.setAttribute('data-lenis-prevent', true)
  }

  close(e) {
    e && e.preventDefault()

    // Update variables
    globalStorage.menuOpen = false

    // Update body className
    domStorage.body.classList.remove('--show-menu')

    // Restart the smooth scroll
    if (viewStorage.hasSmoothScroll) viewStorage.viewScroll.scroll.start()

    // Remove attribute
    this.DOM.nav.removeAttribute('data-lenis-prevent')
  }

  /**
   * Observer
   */

  setObservers() {
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: (self) => {
        // console.log('on up', self.scrollY())
        
        el.classList.remove('--hide')
        
        if (self.scrollY() <= 20) el.classList.remove('--scroll')
        },
      onDown: (self) => {
        // console.log('on down', self.scrollY())

        // Fix the iPhone issue with Safari
        if (self.scrollY() > 0 && !el.classList.contains('--hide')) {
          el.classList.add('--hide')
        }

        if (self.scrollY() > 20 && !el.classList.contains('scroll')) {
          el.classList.add('--scroll')
        }
      },
    })
  }

  /**
   * Resize
   */

  onResize() {
    clearTimeout(this.resizeTimeout)

    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
