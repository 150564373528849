
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import Lightbox from '../modules/lightbox'
import Members from '../modules/members'
import AboutTimeline from '../animations/about-timeline'

export default class PageAbout extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      aboutPresentation: viewStorage.current.querySelector('.AboutPresentation'),
      historyTimeline: viewStorage.current.querySelector('.History .timeline'),
      membersSection: viewStorage.current.querySelector('.Members'),
      lastPublicationsSwiper: viewStorage.current.querySelector('.LastPublications .swiper'),
    }

    // Modules
    if (this.DOM.aboutPresentation) this.lightboxModule = new Lightbox({ section: this.DOM.aboutPresentation })
    if (this.DOM.historyTimeline) this.timelineModule = new AboutTimeline({ section: this.DOM.historyTimeline })
    if (this.DOM.membersSection) this.membersModule = new Members({ section: this.DOM.membersSection })
    if (this.DOM.lastPublicationsSwiper) this.lastPublicationsSwiperModule = new WknSwiper(this.DOM.lastPublicationsSwiper, { slidesPerView: 'auto', spaceBetween: 20, centerInsufficientSlides: true })
  }

  onLeaveCompleted() {
    this.lightboxModule && this.lightboxModule.destroy()
    this.membersModule && this.membersModule.destroy()
    this.timelineModule && this.timelineModule.destroy()
    this.lastPublicationsSwiperModule && this.lastPublicationsSwiperModule.destroy()
  }
}
