import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class PageHeader {
  constructor() {
    this.DOM = { header: viewStorage.current.querySelector('.PageHeader') }
    
    if (!this.DOM.header) return
    
    // DOM 
    this.DOM.links = [...this.DOM.header.querySelectorAll('.content a')]

    // Events
    this.goToTheSection = this.goToTheSection.bind(this)

    this.DOM.links.map(link => {
      const href = link.getAttribute('href')

      if (href && href.startsWith('#')) {
        // Remove the link from the taxi router
        link.setAttribute('data-taxi-ignore', 'true')

        // Add the event
        link.addEventListener('click', this.goToTheSection)
      }
    })
  }

  goToTheSection(e) {
    e && e.preventDefault()

    const href = e.currentTarget.getAttribute('href')
    const targetElement = viewStorage.current.querySelector(href)

    if (targetElement) {
      gsap.to(window, { 
        scrollTo: targetElement, 
        ease: 'expo.inOut', 
        duration: 1.5 
      })
    }
  }

  destroy() {
    if (!this.DOM.links) return

    this.DOM.links.map(link => {
      link.removeAttribute('data-taxi-ignore')
      link.removeEventListener('click', this.goToTheSection)
    })
  }
}
