import { domStorage, viewStorage } from '../_globals'

export default class Lightbox {
  constructor({ section }) {
    // DOM
    this.DOM = { section }
    this.DOM.openButtons = this.DOM.section.querySelectorAll('.preview button.Open')
    this.DOM.closeButtons = this.DOM.section.querySelectorAll('.preview button.Close')

    if (!this.DOM.openButtons && !this.DOM.openButtons.length) return

    this.currentLightbox = null

    // Events
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.closeWithKey = this.closeWithKey.bind(this)

    this.DOM.openButtons.forEach(button => button.addEventListener('click', this.open))
    this.DOM.closeButtons.forEach(button => button.addEventListener('click', this.close))
  }

  open(e) {
    e && e.preventDefault()

    this.currentLightbox = e.currentTarget.nextElementSibling

    // Update classNames
    domStorage.body.classList.add('--oh')
    domStorage.header.classList.add('--hide')

    // Stop scroll
    viewStorage.viewScroll.scroll.stop()

    // Show lightbox
    this.currentLightbox.classList.add('--active')

    // Escape key event
    document.addEventListener('keydown', this.closeWithKey)
  }

  close(e) {
    e && e.preventDefault()

    // Update classNames
    domStorage.body.classList.remove('--oh')
    domStorage.header.classList.remove('--hide')

    // Stop scroll
    viewStorage.viewScroll.scroll.start()

    // Show lightbox
    this.currentLightbox.classList.remove('--active')

    // Escape key event
    document.removeEventListener('keydown', this.closeWithKey)
  }

  closeWithKey(e) {
    if (e.key && e.key === 'Escape') {
      this.close()
    }
  }

  destroy() {
    if (!this.DOM.previewsButtons || !this.DOM.previewsButtons.length) return

    // Events
    this.DOM.previewsButtons.forEach(button => button.removeEventListener('click', this.open))
    this.DOM.closeButtons.forEach(button => button.removeEventListener('click', this.close))
  }
}
