import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, SplitText, DrawSVGPlugin)

/**
 * All animations for different parts of the DOM
 */

export const header = () => {
  if (!domStorage.header) return

  const tween = gsap.fromTo(domStorage.header, {
    yPercent: -30,
    opacity: 0,
  }, {
    yPercent: 0,
    opacity: 1,
    ease: 'expo.inOut',
    duration: 1.5,
    onComplete: () => {
      gsap.set(domStorage.header, { clearProps: 'yPercent,scale,opacity' })
    }
  })

  return tween
}

export const basicIntro = () => {
  const tl = gsap.timeline({
    defaults: { ease: 'expo.inOut', duration: 1.5 },
  })

  tl.fromTo(viewStorage.current, {
    opacity: 0,
    y: 50,
  }, {
    opacity: 1,
    y: 0,
    clearProps: 'opacity,y'
  }, 0)

  return tl
}

export const pageHeaderIntro = () => {
  const { current } = viewStorage
  const pageHeader = current.querySelector('.PageHeader')

  if (!pageHeader) return

  /**
   * DOM
   */
  const subtitle = pageHeader.querySelector('.container > .subtitle')
  const h1 = pageHeader.querySelector('h1')
  const networkBackground = pageHeader.querySelector('.visual__container.--network .visual')
  const button = pageHeader.querySelector('.container > .Btn')

  // const banner = current.querySelector('.PageHeader + .Banner')
  const sections = [...viewStorage.current.children].slice(1)

  /**
   * SplitText
   */
  const subtitleChars = new SplitText(subtitle, { type: 'chars,lines' })
  const h1Chars = new SplitText(h1, { type: 'chars,lines' })

  /**
   * Main Timeline
   */
  const tl = gsap.timeline({
    defaults: { ease: 'power3.inOut', duration: 1 },
    onComplete: () => {
      // Clear SplitText
      if (subtitle) subtitleChars.revert()
      if (h1) h1Chars.revert()
    }
  })

  /**
   * General
   */
  // ...
  
  /**
   * Pages
  */
  // ...

  return tl
}
