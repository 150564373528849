
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import PageHeaderHome from '../animations/page-header-home'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import Solutions from '../animations/solutions'

export default class PageAbout extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderHome'),
      sectionSolutions: viewStorage.current.querySelector('.Solutions'),
      lastPublicationsSwiper: viewStorage.current.querySelector('.LastPublications .swiper'),
      lastNewsSwiperMobile: viewStorage.current.querySelector('.LastNews .swiper.--mobile')
    }

    // Modules
    if (this.DOM.pageHeader) this.pageHeaderModule = new PageHeaderHome(this.DOM.pageHeader)
    if (this.DOM.sectionSolutions) this.solutionsModule = new Solutions(this.DOM.sectionSolutions)
    if (this.DOM.lastPublicationsSwiper) this.lastPublicationsSwiperModule = new WknSwiper(this.DOM.lastPublicationsSwiper, { slidesPerView: 'auto', spaceBetween: 20, centerInsufficientSlides: true })
    if (this.DOM.lastNewsSwiperMobile) this.lastNewsSwiperModule = new MobileSwiper(this.DOM.lastNewsSwiperMobile)
  }

  onLeaveCompleted() {
    this.pageHeaderModule && this.pageHeaderModule.destroy()
    this.solutionsModule && this.solutionsModule.destroy()
    this.lastPublicationsSwiperModule && this.lastPublicationsSwiperModule.destroy()
    this.lastNewsSwiperModule && this.lastNewsSwiperModule.destroy()
  }
}
