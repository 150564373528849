
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'

export default class PageGutenberg extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      gutenbergContent: viewStorage.current.querySelector('.GutenbergContent'),
      pageHeader: viewStorage.current.querySelector('.GutenbergContent .PageHeader')
    }

    if (!this.DOM.gutenbergContent) return
    
    this.DOM.gutenbergContent.prepend(this.DOM.pageHeader)
  }

  onLeaveCompleted() {
    if (!this.DOM.gutenbergContent) return

    // Destroy
    this.DOM.gutenbergContent.removeChild(this.DOM.pageHeader)
  }
}
