import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger, SplitText)

export default class PageHeaderHome {
  constructor(section) {
    if (!section) return

    this.DOM = { section }
    this.DOM.container = this.DOM.section.querySelector('.container')
    // State 1
    this.DOM.state1 = this.DOM.section.querySelector('.state-1')
    this.DOM.state1Title = this.DOM.state1.querySelector('.state-title')
    this.DOM.state1Visual = this.DOM.state1.querySelector('.visual__container')
    // State 2
    this.DOM.state2 = this.DOM.section.querySelector('.state-2')
    this.DOM.state2Title = this.DOM.state2.querySelector('.state-title')
    this.DOM.state2VideoC = this.DOM.state2.querySelector('.video-container')
    this.DOM.state2Video = this.DOM.state2VideoC.querySelector('video')

    this.setTimeline()
  }

  setTimeline() {
    // Split titles by lines
    this.DOM.state1TitleLines = new SplitText(this.DOM.state1Title, { type: 'lines' })
    this.DOM.state2TitleLines = new SplitText(this.DOM.state2Title, { type: 'lines' })

    // Timeline
    this.timeline = gsap.timeline({
      defaults: {
        duration: 1,
        ease: 'none' 
      },
      scrollTrigger: {
        trigger: this.DOM.state2,
        start: 'top bottom',
        end: 'bottom center',
        scrub: true,
      }
    })

    this.timeline.to(this.DOM.state1TitleLines.lines, {
      opacity: 0,
      scale: 0.6,
      xPercent: (i, el, els) => i % 2 === 0 ? -50 : 50,
    }, 0)

    this.timeline.to(this.DOM.state1Visual, {
      scale: 1.85
    }, 0)
    
    this.timeline.fromTo(this.DOM.state2TitleLines.lines, {
      opacity: 0,
      scale: 0.6,
      xPercent: (i, el, els) => i % 2 === 0 ? 50 : -50
    }, {
      opacity: 1,
      scale: 1,
      xPercent: 0
    })

    this.timeline.fromTo(this.DOM.state2VideoC, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.5,
      onStart: _ => this.DOM.state2Video.play()
    }, '-=1.5')
  }

  destroy() {
    if (this.DOM.state1TitleLines) this.DOM.state1TitleLines.revert()
    if (this.DOM.state2TitleLines) this.DOM.state2TitleLines.revert()
    if (this.timeline) this.timeline.kill()
  }
}
