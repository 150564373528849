import { domStorage } from "../_globals"
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Footer {
  constructor() {
    this.DOM = { el: domStorage.footer }

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setParallax()
  }

  setParallax() {
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.tl = gsap.timeline({
          defaults: { ease: 'none' },
          scrollTrigger: {
            trigger: this.DOM.el,
            start: 'top bottom',
            end: 'bottom bottom',
            scrub: true, 
            // markers: true
          }
        })

        this.tl.from(this.DOM.el.children[0], { y: -80, opacity: 0.5 })
      }
    })
  }

  onResize() {
    ScrollTrigger.refresh()
  }
}
