export default class Members {
  constructor({ section }) {
    if (!section) return

    // DOM
    this.DOM = {
      inputs: section.querySelectorAll('form input'),
      cards: section.querySelectorAll('.MemberCard'),
      select: section.querySelector('select')
    }
    console.log(this.DOM)

    if (!this.DOM.cards) return

    // Events
    this.updateCards = this.updateCards.bind(this)
    this.updateCards()

    this.DOM.inputs.forEach(input => input.addEventListener('change', this.updateCards))
    this.DOM.select && this.DOM.select.addEventListener('change', this.updateCards)

    // By default, all the cards are active because of the 'All' filter activated
    this.DOM.cards.forEach(card => card.classList.add('--active'))
  }

  updateCards(e) {
    e && e.preventDefault()

    const statusValue = Array.from(this.DOM.inputs).find(input => input.checked).value
    const positionValue = this.DOM.select?.value || 'all'

    this.DOM.cards.forEach(card => {
      card.classList.remove('--active')
      card.classList.add('--transition')


      setTimeout(() => {
        const hideClass = '--hide'
        const transitionClass = '--transition'
        const cardStatus = card.dataset.status
        const cardPosition = card.dataset.position

        if (statusValue === 'all' && positionValue === 'all') {
          card.classList.remove(hideClass)
          card.classList.remove(transitionClass)
          return
        } else {
          if (statusValue !== 'all' && positionValue === 'all') {
            if (cardStatus === statusValue) {
              card.classList.remove(hideClass)
            } else {
              card.classList.add(hideClass)
            }
          } else if (statusValue === 'all' && positionValue !== 'all') {
            if (cardPosition === positionValue) {
              card.classList.remove(hideClass)
            } else {
              card.classList.add(hideClass)
            }
          } else {
            if (cardStatus === statusValue && cardPosition === positionValue) {
              card.classList.remove(hideClass)
            } else {
              card.classList.add(hideClass)
            }
          }
        }

        card.classList.remove('--transition')
      }, 550)
    })
  }

  destroy() {
    this.DOM.inputs.forEach(input => input.removeEventListener('change', this.updateCards))
  }
}
