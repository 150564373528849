import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class AboutTimeline {
  constructor({ section }) {
    if (!section) return

    // DOM
    this.DOM = { section }
    this.DOM.items = this.DOM.section.querySelectorAll('.timeline ul li')
    this.DOM.line = this.DOM.section.querySelector('.line')
    this.DOM.lineActive = this.DOM.line.querySelector('.line-active')

    if (!this.DOM.section) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setLineAnimation() 
    this.setItemsAnimation()
  }

  setLineAnimation() {
    this.tween = gsap.to(this.DOM.lineActive, {
      scrollTrigger: {
        trigger: this.DOM.section,
        start: 'top center',
        end: 'bottom bottom',
        scrub: true
      },
      clipPath: 'inset(0% 0% 0% 0%)',
      ease: 'none',
    })
  }

  setItemsAnimation() {
    this.mm.add(this.responsiveObj, context => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.DOM.items.forEach((item, index) => {
          item.tl = gsap.timeline({
            defaults: {
              duration: 1,
              ease: 'expo.out'
            },
            scrollTrigger: {
              trigger: item,
              start: 'top 80%',
              end: 'bottom top',
              toggleActions: 'play pause resume reverse'
            },
          })

          item.tl.fromTo(item.children[0], {
            scale: 0
          }, {
            scale: 1,
            borderColor: '#009DB4'
          }, 0)

          item.tl.fromTo(item.children[1], {
            xPercent: () => index % 2 === 0 ? 20 : -20,
            opacity: 0
          }, {
            xPercent: 0,
            opacity: 1
          }, 0)
        })
      } else {
        this.DOM.items.forEach((item, index) => {
          item.tl = gsap.timeline({
            defaults: {
              duration: 1,
              ease: 'expo.out'
            },
            scrollTrigger: {
              trigger: item,
              start: 'top 80%',
              end: 'bottom top',
              toggleActions: 'play pause resume reverse'
            },
          })

          item.tl.fromTo(item.children[0], {
            scale: 0
          }, {
            scale: 1,
            borderColor: '#009DB4'
          }, 0)
          
          item.tl.fromTo(item.children[1], {
            xPercent: 20,
            opacity: 0
          }, {
            xPercent: 0,
            opacity: 1
          }, 0)
        })
      }
    })

  }

  destroy() {
    if (this.tween) this.tween.kill()
    if (this.mm) this.mm.revert()
  }
}
